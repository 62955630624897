import React from 'react'
import { FaRegSun, FaWater, FaSpa, FaUserMd, FaDumbbell } from 'react-icons/fa'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

function Treatments(props) {
    const style = props.style;
    const backgroundFluidImageStack = [
        props.image.node.childImageSharp.fluid,
        `linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0.08) 100%)`
    ].reverse()

    return (
        <section id="Treatments" className={style.Treatments}>
            <BackgroundImage
                Tag={`div`}
                id={`main-treatments`}
                className={style.Treatments__Img}
                fluid={backgroundFluidImageStack}
            >
                <h2 className={style.Treatments__Heading} data-aos="fade-left">Nasza oferta</h2>
                <ul className={style.Treatments__List}>
                    <li className={style.Treatments__Item} data-aos='fade-up'>
                        <i className={style.Treatments__Item__Icon}><FaUserMd /></i>
                        <h2 className={style.Treatments__Item__Heading}>Fizjoterapia</h2>
                        <h6 className={style.Treatments__Item__Paragraph}>Metody leczenia oparte są w głównej mierze na różnych formach energii fizycznej występujących w przyrodzie.</h6>
                    </li>
                    <li className={style.Treatments__Item} data-aos='fade-up'>
                        <i className={style.Treatments__Item__Icon}><FaWater /></i>
                        <h2 className={style.Treatments__Item__Heading}>Hydroterapia</h2>
                        <h6 className={style.Treatments__Item__Paragraph}>Forma leczenia bodźcowego polegająca na zewnętrznym stosowaniu wody. </h6>
                    </li>
                    <li className={style.Treatments__Item} data-aos='fade-up'>
                        <i className={style.Treatments__Item__Icon}><FaDumbbell /></i>
                        <h2 className={style.Treatments__Item__Heading}>Kinezyterapia</h2>
                        <h6 className={style.Treatments__Item__Paragraph}>Metoda leczenia ruchem. Podstawą tej metody są ćwiczenia ruchowe mające na celu maksymalne usunięcie niesprawności fizycznej i przygotowanie do dalszej rehabilitacji.</h6>
                    </li>
                    <li className={style.Treatments__Item} data-aos='fade-up'>
                        <i className={style.Treatments__Item__Icon}><FaRegSun /></i>
                        <h2 className={style.Treatments__Item__Heading}>Leczenie Ciepłem</h2>
                        <h6 className={style.Treatments__Item__Paragraph}>Metoda polegająca na przekazywaniu do organizmu energii cieplnej na drodze przewodzenia, przenoszenia lub promieniowania.</h6>
                    </li>
                    <li className={style.Treatments__Item} data-aos='fade-up'>
                        <i className={style.Treatments__Item__Icon}><FaSpa /></i>
                        <h2 className={style.Treatments__Item__Heading}>Masaż</h2>
                        <h6 className={style.Treatments__Item__Paragraph}>Zabiegi polegające na sprężystym odkształcaniu tkanek</h6>
                    </li>
                </ul>
                <div className={style.Treatments__More} data-aos="fade-up">
                    <Link to='/zabiegi/' className={[style.Link, style.Link__Red, style.Link__Big].join(" ")}>Więcej</Link>
                </div>
            </BackgroundImage>
        </section>
    )
}

export default Treatments
