import React, { useEffect } from "react"
import { useStaticQuery, graphql} from "gatsby"
import style from './main.module.scss'
import Jumbotron from './Jumbotron'
import Info from './Info'
import Hours from './Hours'
import Treatments from "./Treatments"
import News from "./News/News"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Main = () => {
  const {
    strapiGodzinyRejestracji,
    strapiKontakt,
    strapiStronaGlowna, 
    allStrapiNews,
    mainImages
  } = useStaticQuery(query);

  const imageArr = mainImages.edges;

  useEffect(()=>{
    AOS.init({
      duration : 500
    })
  }, []);

  const findImage = (name) => {
    for(let i=0; i < imageArr.length; i++) {
      if(imageArr[i].node.name == name) {
        return imageArr[i];
      }
    }
    return null;
  }

  return (
    <main className={style.Main}>
      <Jumbotron contact={strapiKontakt} style={style} image={findImage('jumbotron-main')}/>
      <Info info={strapiStronaGlowna} style={style} />
      <Hours hours={strapiGodzinyRejestracji} style={style} image={findImage('miejsce-2')}/>
      <Treatments style={style} image={findImage('treatments-background')}/>
      <News news={allStrapiNews} style={style} images={[findImage('zabieg-1'), findImage('zabieg-2'), findImage('zabieg-3') , findImage('zabieg-4')]}/>
    </main>
  )
}

export default Main

const query = graphql`{
  strapiGodzinyRejestracji {
    pn
    wt
    sr
    czw
    pt
    sb
    nd
  }
  strapiKontakt {
    email
    rejestracja_telefon
    numer_budynku
    miasto
    ulica
  }
  strapiStronaGlowna {
    ludzik {
      opis
      tytul
    }
    dom {
      opis
      tytul
    }
    reh_med {
      opis
      tytul
    }
    serce {
      opis
      tytul
    }
    blok {
      opis
      tytul
    }
  }

  allStrapiNews(sort: {order: DESC, fields: data_publikacji}) {
    nodes {
      data_publikacji
      tekst
    }
  }

  mainImages: allFile(filter: {relativeDirectory: {eq: "main"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          fluid(maxWidth: 2100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  }
}`