import React from 'react'
import BackgroundImage from 'gatsby-background-image'

function Hours(props) {
    const style = props.style;
    const { pn, wt, sr, czw, pt, sb, nd } = props.hours
    const backgroundFluidImageStack = [
        props.image.node.childImageSharp.fluid,
        `linear-gradient(0deg, rgba(0, 78, 134, 0.79), rgba(0, 78, 134, 0.79))`
      ].reverse()

    return (
        <section id="Hours" data-aos="fade-left">
            <BackgroundImage
                Tag={`div`}
                id={`main-hours`}
                className={style.Hours__Image}
                fluid={backgroundFluidImageStack}
            >
                <div className={style.Hours}>
                    <p className={style.Hours__Paragraph}>
                        <span className={style.Bold}>Godziny rejestracji: </span >
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>pon: </span>
                            {pn}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>wt: </span>
                            {wt}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>sr: </span>
                            {sr}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>czw: </span>
                            {czw}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>pt: </span>
                            {pt}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>sob: </span>
                            {sb}
                        </span>
                        <span className={style.Hours__Hour}>
                            <span className={style.Bold}>niedz: </span>
                            {nd}
                        </span>
                    </p>
                </div>
            </BackgroundImage>
        </section>
    )
}

export default Hours
