import React from 'react'
import NewsItem from './News-Item'
import Img from "gatsby-image"


function News(props) {
  const style = props.style;
  const news = props.news.nodes;

  const handleImages = () => {
    const result = []
    props.images.forEach((image, index) => {
      result.push(
        <Img
          key={index}
          className={style.News__Image}
          fluid={image.node.childImageSharp.fluid}
        />
      )
    });
    return result
  }

  return (
    <section className={style.News}>
      <div className={style.News__Images}>
        {handleImages()}
      </div>

      <div className={style.News__Info} >

        <h2 className={style.News__Info__Heading} data-aos="fade-left">Aktualności</h2>
        <ul className={style.News__Info__List} data-aos="fade-up">
          {
            news.map((item, index) => {
              return <NewsItem key={index} style={style} date={item.data_publikacji} info={item.tekst} />
            })
          }
        </ul>
      </div>

    </section>
  )
}

export default News
