import React from 'react'
import { FaRegEnvelope, FaMap, FaPhoneAlt } from 'react-icons/fa'
import BackgroundImage from 'gatsby-background-image'

function Jumbotron(props) {
    const { email, rejestracja_telefon, numer_budynku, miasto, ulica } = props.contact;
    const style = props.style;

    return (
        <section id="Jumbotron">
            <div className={style.Jumbotron}>
                <div className={style.Jumbotron__container_left}>
                    <div className={style.Jumbotron__container__quote}>
                        <h1 className={style.Jumbotron__Quote} data-aos="fade-left">
                        Twoje <span className={style.BoldQuote}>zdrowie</span>
                        <br />
                        Nasza <span className={style.BoldQuote}>misja</span>
                        </h1>
                    </div>
                </div>

                <div className={style.Jumbotron__container_right}>
                    <BackgroundImage
                        Tag={`div`}
                        id={`main-jumbotron`}
                        className={style.Jumbotron__img}
                        fluid={props.image.node.childImageSharp.fluid}
                    >
                    </BackgroundImage>
                </div>
            </div>

            <div className={style.Contact}>
                <a href={`https://www.google.pl/maps/place/${ulica}+${numer_budynku},${miasto}/`}  className={style.Contact__Location} data-aos='fade-left'>
                    <span className={style.centeredBothLabel}>
                        <FaMap className={style.Contact__Location__Icon} />
                        {ulica} {numer_budynku}, {miasto}
                    </span>
                </a>
                <a href={"tel:" + rejestracja_telefon} className={style.Contact__Telephone} data-aos='fade-up'>
                    <span className={style.centeredBothLabel}>
                        <FaPhoneAlt className={style.Contact__Telephone__Icon} />
                        {rejestracja_telefon}
                    </span>
                </a>
                <a href={"mailto:" + email} className={style.Contact__Mail} data-aos='fade-right'>
                    <span className={style.centeredBothLabel}>
                        <FaRegEnvelope className={style.Contact__Mail__Icon} />
                        {email}
                    </span>
                </a>
            </div>
        </section>
    )
}

export default Jumbotron
