import React from "react"
import Layout from "../components/layout"
import Main from "../components/Main/Main"
import SEO from "../components/seo"
const IndexPage = () => (
  <Layout>
    <SEO
      meta={[
        {
          name: `google-site-verification`,
          content: "c6MDkOQemlOFfGjAOPgrKrslIn7JJM0-gYt2K_nJ9SQ",
        },
      ]}
      title="Zakład Rehabilitacji Leczniczej Reh-Med Legnica"
      isIndex
      keywords="Fizjoterapia Legnica,
       Fizjoterapeuta Legnica, Fizjoterapeutka Legnica,
        Masaż Legnica, Masażysta Legnica,
         Masażystka Legnica, Hydroterapia Legnica,
          Kinezyterapia Legnica, lekarz Legnica,
           terapeuta Legnica, ból pleców Legnica,
            masaże Legnica, rehabilitacja Legnica
            Anna Mysyk Legnica, Anna Mysyk Legnica Rehabilitacja,"
    />
    <Main />
  </Layout>
)

export default IndexPage
