import React from 'react'

function NewsItem( props ) {
    const style = props.style;

    return (
        <div className={style.News__Info__Item}>
            <p>{props.date}r - {props.info}</p>
        </div>
    )
}

export default NewsItem
