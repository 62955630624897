import React from 'react'
import { FaHospital, FaHeart, FaRunning, FaAward } from 'react-icons/fa'
import { Link } from "gatsby"

function Info(props) {
    const { ludzik, dom, reh_med, serce, blok } = props.info;
    const style = props.style

    return (
        <section id="Info" className={style.Info}>
            <div className={style.Info__Leftcontainer} data-aos='fade-up'>
                <h1 className={style.Info__Header}>
                    Zakład Rehabilitacji Leczniczej <span className={style.Info__Span}>{reh_med.tytul}</span>
                </h1>
                <h5 className={style.Info__Paragraph}>
                    {reh_med.opis}
                </h5>
                <div className={style.Info__Leftcontainer__Links}>
                    <Link to="/kontakt/" className={[style.Link, style.Link__Blue].join(" ")}>KONTAKT</Link>
                    <Link to="/cennik/" className={[style.Link, style.Link__Red].join(" ")}>CENNIK</Link>
                </div>
            </div>

            <div className={style.Info__Rightcontainer} >
                <div className={style.Info__Rightcontainer__Grid}>
                    <div className={style.Info__Rightcontainer__Item} data-aos='fade-up'>
                        <h3 className={style.Info__Rightcontainer__Item__Title}>
                            <span className={[style.centeredLabel, style.red].join(" ")}>
                                <FaAward className={style.Info__Icon} />
                                {dom.tytul}
                            </span>
                        </h3>
                        <p className={style.Info__Rightcontainer__Item__Paragraph}> {dom.opis} </p>
                    </div>
                    <div className={style.Info__Rightcontainer__Item} data-aos='fade-up'>
                        <h3 className={style.Info__Rightcontainer__Item__Title}>
                            <span className={style.centeredLabel}>
                                <FaRunning className={style.Info__Icon} />
                                {ludzik.tytul}
                            </span>
                        </h3>
                        <p className={style.Info__Rightcontainer__Item__Paragraph}> {ludzik.opis} </p>
                    </div>
                    <div className={style.Info__Rightcontainer__Item} data-aos='fade-up'>
                        <h3 className={style.Info__Rightcontainer__Item__Title}>
                            <span className={style.centeredLabel}>
                                <FaHeart className={style.Info__Icon} />
                                {serce.tytul}
                            </span>
                        </h3>
                        <p className={style.Info__Rightcontainer__Item__Paragraph}> {serce.opis} </p>
                    </div>
                    <div className={style.Info__Rightcontainer__Item} data-aos='fade-up'>
                        <h3 className={style.Info__Rightcontainer__Item__Title}>
                            <span className={[style.centeredLabel, style.red].join(" ")}>
                                <FaHospital className={style.Info__Icon} />
                                {blok.tytul}
                            </span>
                        </h3>
                        <p className={style.Info__Rightcontainer__Item__Paragraph}> {blok.opis} </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Info
